import React from "react"
import { Link } from "gatsby"

import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import MenuIcon from "@material-ui/icons/Menu"

import greenstoneLogo from "../images/greenstone-logo.png"

import Hero from "./hero"

const appToolbarHeight = "80px"

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: "transparent",
    position: "relative",
    boxShadow: "none",
    marginBottom: props => props.data.headerMb,
  },
  activeLink: {
    color: `${theme.link.active} !important`,
  },
  buttonBaseRoot: {
    textAlign: "center",
  },
  drawerPaper: {
    width: "180px",
    background: "rgba(255,255,255,0.8)",
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: appToolbarHeight,
  },
  toolBar: {
    zIndex: "1",
    backgroundColor: "rgba(255,255,255,0.7)",
    position: "absolute",
    width: "100%",
    height: appToolbarHeight,
    justifyContent: "space-between",
  },
  mainNav__item: {
    [theme.breakpoints.up("sm")]: {
      margin: "0 1rem",
      listStyle: "none",
      textTransform: "uppercase",
    },
  },
  mainNav__link: {
    fontSize: "1.25rem",
    color: theme.link.primary,
    textDecoration: "none",
    "&:hover": {
      color: theme.link.hover,
    },
  },
  mobileMainNav__link: {
    fontSize: "1.25rem",
    color: theme.link.primary,
    textDecoration: "none",
  },
}))

const Header = props => {
  const classes = useStyles(props)
  const { data } = props

  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolBar}>
          <Box>
            <Link to="/">
              <img src={greenstoneLogo} alt="greenstone wellness" />
            </Link>
          </Box>
          <Box component="nav" display={{ xs: "none", sm: "block" }}>
            <Box component="ul" display={{ sm: "flex" }}>
              <li className={classes.mainNav__item}>
                <Link
                  className={classes.mainNav__link}
                  to="/"
                  activeClassName={classes.activeLink}
                >
                  home
                </Link>
              </li>
              <li className={classes.mainNav__item}>
                <Link
                  className={classes.mainNav__link}
                  to="/about"
                  activeClassName={classes.activeLink}
                >
                  about
                </Link>
              </li>
              <li className={classes.mainNav__item}>
                <Link
                  className={classes.mainNav__link}
                  to="/services"
                  activeClassName={classes.activeLink}
                >
                  services
                </Link>
              </li>
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "none" }}>
            <IconButton
              color="inherit"
              aria-label="Open menu"
              onClick={handleDrawerOpen}
              onKeyDown={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
        <Hero
          headline={data.headline}
          headlineMaxWidth={data.headlineMaxWidth}
          tagline={data.tagline}
          taglineMaxWidth={data.taglineMaxWidth}
          primaryBtnLabel={data.primaryBtnLabel}
          primaryHref={data.primaryHref}
          secondaryHref={data.secondaryHref}
          secondaryBtnLabel={data.secondaryBtnLabel}
          index={data.index}
          heroVh={data.heroVh}
        />
      </AppBar>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <div
          className={classes.drawerHeader}
          role="menu"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
          tabIndex={0}
        >
          <CloseIcon fontSize="large" />
        </div>
        <Divider />
        <List>
          <ListItem
            button
            classes={{
              root: classes.buttonBaseRoot,
            }}
          >
            <ListItemText>
              <Link to="/" className={classes.mobileMainNav__link}>
                HOME
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem button classes={{ root: classes.buttonBaseRoot }}>
            <ListItemText>
              <Link to="/about" className={classes.mobileMainNav__link}>
                ABOUT
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem button classes={{ root: classes.buttonBaseRoot }}>
            <ListItemText>
              <Link to="/services" className={classes.mobileMainNav__link}>
                SERVICES
              </Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  )
}

export default Header
