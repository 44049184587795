import React, { Component } from "react"

import { Container, Grid, withStyles, Box, Typography } from "@material-ui/core"

import MainContent from "./main-content"
import FormErrors from "./form-errors"
import CoButton2 from "./co-button-2"
import CoLink from "./co-link"
import { useTheme } from "@material-ui/styles"

const styles = {
  input: {
    width: "100%",
    height: "65px",
    border: "1px solid white",
    boxShadow: "inset 0px 2px 5px rgba(0, 0, 0, 0.1)",
    padding: "1.5rem",
    font: "inherit",
    outline: "0",
    "&::placeholder": {
      fontStyle: "italic",
      fontWeight: "300",
      fontSize: "1.25rem",
    },
  },
  label: {
    display: "none",
  },
  textarea: {
    resize: "none",
    height: "200px",
  },
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactIntro = () => {
  return (
    <MainContent title="Do You Have Any Specific Questions" mb="1rem">
      <Typography>
        Let me know if you have questions that is not stated in the{" "}
        <CoLink to="/about">ABOUT</CoLink> page. It will take a few days but I
        will get back to you as soon as possible. Thank you for your interest
        and have a good day!
      </Typography>
    </MainContent>
  )
}

const ContactSuccess = props => {
  const theme = useTheme()

  return (
    <MainContent title="Thank You For Contacting Us" mb="1rem">
      <Typography>
        It will take us about 2-7 business days to get back to you on your
        message. While waiting you can book for appointments or browse around
        the website for more information.
      </Typography>
      <Box mt="2.5rem" textAlign="center">
        <CoButton2 color="secondary" onClick={props.onMessageAgain}>
          MESSAGE AGAIN
        </CoButton2>
        <CoButton2 color="primary" href={theme.data.janeApp}>
          BOOK ONLINE
        </CoButton2>
        <CoButton2 color="secondary" href="/about">
          KNOW MORE
        </CoButton2>
      </Box>
    </MainContent>
  )
}

class FormContact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      formErrors: { email: "" },
      emailValid: false,
      formValid: false,
      fromSubmitted: false,
    }
  }

  handleUserInput(e) {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors
    let emailValid = this.state.emailValid

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        fieldValidationErrors.email = emailValid ? "" : " is invalid"
        break
      default:
        break
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid,
      },
      this.validateForm
    )
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid,
    })
  }

  handleSubmit(e) {
    e.preventDefault()

    // Form submission in progress. Do not allow user to re-submit.
    this.setState({
      formValid: false,
    })
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(
        // Delete form fields and send success message.
        this.setState({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          fromSubmitted: true,
        })
      )
      .catch(error => alert(error))
  }

  onMessageAgain() {
    console.log("clicked")
    this.setState({
      fromSubmitted: false,
    })
  }

  render() {
    const { classes } = this.props
    let content

    if (!this.state.fromSubmitted) {
      content = (
        <React.Fragment>
          <ContactIntro />
          <Container maxWidth="sm">
            <form
              autoComplete="off"
              noValidate
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={event => this.handleSubmit(event)}
            >
              <input type="hidden" name="form-name" value="contact" />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <label className={classes.label} htmlFor="first-name">
                    First Name
                  </label>
                  <input
                    className={classes.input}
                    name="firstName"
                    value={this.state.firstName}
                    placeholder="First Name"
                    onChange={event => this.handleUserInput(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className={classes.label} htmlFor="last-name">
                    Last Name
                  </label>
                  <input
                    className={classes.input}
                    name="lastName"
                    value={this.state.lastName}
                    placeholder="Last Name"
                    onChange={event => this.handleUserInput(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className={classes.label} htmlFor="email">
                    Email Address
                  </label>
                  <input
                    className={classes.input}
                    name="email"
                    value={this.state.email}
                    placeholder="Email Address"
                    onChange={event => this.handleUserInput(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <textarea
                    className={`${classes.input} ${classes.textarea}`}
                    name="message"
                    value={this.state.message}
                    placeholder="Write your question here..."
                    onChange={event => this.handleUserInput(event)}
                  ></textarea>
                </Grid>
              </Grid>
              <FormErrors formErrors={this.state.formErrors} />
              <Box textAlign="center">
                <CoButton2
                  disabled={!this.state.formValid}
                  color="primary"
                  type="submit"
                >
                  SEND
                </CoButton2>
              </Box>
            </form>
          </Container>
        </React.Fragment>
      )
    } else {
      content = (
        <ContactSuccess onMessageAgain={this.onMessageAgain.bind(this)} />
      )
    }
    return <Box>{content}</Box>
  }
}

export default withStyles(styles)(FormContact)
